html {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
html, body, #app, .wh {
  height: 100%;
  width: 100%;
}
#app, .wv {
  display: flex;
  flex-direction: column;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.forma-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #bdbdbd;
  -webkit-border-radius: 6px;
  border-radius: 6px
}

::-webkit-scrollbar-thumb:hover {
  background-color: #9e9e9e
}

::-webkit-scrollbar-thumb:active {
  background-color: #9e9e9e
}

::-webkit-scrollbar-corner {
  background: transparent
}

::-webkit-scrollbar-track {
  background-color: #eeeeee
}

::-webkit-scrollbar-track:hover {
  background-color: #e0e0e0
}
